import { combineReducers } from 'redux'

// // Permission Matrix
// import permissionMatrix from "../reducer/permissionMatrix"

import onboarding from "../reducer/onboarding"
// Theme Reducer
import ThemeOptions from "../reducer/themeReducer"
// Admin Reducer
import {adminReducer} from "../reducer/admin/adminReducer"
import {councelorReducer} from "../reducer/admin/adminReducer"
import databaseManagement from "../reducer/admin/databaseManagement"
// Consumer Reducer
import consumerReducer from "../reducer/consumer/consumer"

// Attorney Reducer
import attorneyReducer from "../reducer/attorney/attorney"

// Debtor Reducer
import debtorReducer from "../reducer/debtor/debtorReducer"
import helper from './helper'
// loading reducer
import loading from './loading'

// Constant reducer
import constants from '../reducer/constant'

// Attoney module
import attorneyModule from '../reducer/attorneyModule/attorney'

export default combineReducers({
    // permission : permissionMatrix,
    userOnboarding: onboarding,
    ThemeOptions,
    loadingStatus: loading,
    admin: adminReducer,
    councelor : councelorReducer,
    consumer: consumerReducer,
    attorney: attorneyReducer,
    debtor: debtorReducer,
    helpers: helper,
    constant : constants,
    databaseManagement : databaseManagement,
    attorneyModule : attorneyModule
})