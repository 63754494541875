import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import './assets/base.scss';
import Main from './components/Main/index';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory as createHistory } from 'history';
import i18n from './i18n';
import ErrorBoundary from './utils/errorBoundary';
import ScrollToTop from './globalComponents/ScrollToTop';

const rootElement = document.getElementById('root');

const history = createHistory();

const renderApp = (Component) => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop />
          {/* <ErrorBoundary> */}
          <Component />
          {/* </ErrorBoundary> */}
        </Router>
      </Provider>
    </I18nextProvider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./components/Main/index', () => {
    const NextApp = require('./components/Main/index').default;
    renderApp(NextApp);
  });
}

serviceWorker.register();
