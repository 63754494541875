export const PAGINATION = 'PAGINATION';
export const MODAL_STATUS = 'MODAL_STATUS';

// Pagination action
export const handlePagination = (page) => {
  return {
    type: PAGINATION,
    payload: page,
  };
};

// Handle Modal
export const handleModalStatus = (payload) => {
  return {
    type: MODAL_STATUS,
    payload: payload,
  };
};
