import {
  ATTORNEY_SPECIAL_PRICING,
  FOLIO_ATTORNEY_DETAILS,
  GET_STATES,
  GET_COUNTY_BY_STATE_CODE,
  ATTORNEY_ACCOUNT_AGENT_LIST,
  LIST_ATTORNEYS,
  LIST_ATTORNEY_BY_STATE,
  MY_RECENT_ATTORNEY,
  RECENT_OR_PENDING_REQUEST,
  REFERRAL_SOURCE,
  JUDICIAL_DISTRICTS,
  LIST_ATTORNEY_BY_NOT_USING_US_CODE,
  LIST_ATTORNEY_BY_STATUS,
  LIST_ATTORNEY_SPECIAL_PRICING,
  LIST_ATTORNEY_ACTIVE_BILLING,
  LIST_ATTORNEY_WITH_NEW_BILLING,
  MOST_ACTIVE_ATTORNEYS,
  MOST_ACTIVE_DATE_RANGE,
  MOST_ACTIVE_DATE_RANGE_AND_COMPARISON,
  NEW_ATTORNEYS,
  ATTORNEY_SEARCH,
  FREE_TRIALS_OFFERED,
  FREE_TRIALS_USED,
  LETTERS_SENT,
  REQUESTS,
  LIST_BY_STATE,
  ATTORNEY_PROFILE_REQUEST,
  DELETE_ATTORNEY_PROFILE_REQUEST,
  EDIT_ATTORNEY_REQUEST_LIST,
  EDIT_RECENT_REQUEST,
  LIST_BY_STATUS,
  NOT_USING_US_CODE,
  ACTIVE_BILLS,
  ATTORNEY_LETTERS,
  ATTORNEY_LETTERS_PRIORS,
  ATTORNEY_ADDED_NEW_BILLING,
  SPECIAL_PRICING,
  MOST_ACTIVE_DATE_RANGE_ATTORNEYS,
  ATTORNEY_ADDED_NEW_DATE_RANGE_PARAMS,
  FREE_TRIALS_PARAMS,
  LETTER_SENT_PARAMS,
  REQUEST_INBOUND_PARAMS,
  VOLUME_REPORT_PARAMS,
  FOLIO_ATTORNEY_DETAILS_TO_EDIT,
  FREE_TRIAL_DATE_RANGE_ATTORNEYS,
  FIVE_RECENT_ATTORNEY,
} from '../../constants/types';

const initialValue = {
  isFetching: true,
  attorneyProfile: {},
  attorneyProfileToEdit: {},
  specialPricingDetails: {},
  states: [],
  agentList: [],
  counties: [],
  attorneyListByState: [],
  attorneyList: [],
  recentOrPendingRequests: [],
  recentAttorneys: [],
  referralSources: [],
  judicialDistricts: [],
  attorneyListByStatus: [],
  attorneyListByNotUsingUsCode: [],
  attorneyListWithNewBilling: [],
  attorneyListByActiveBilling: [],
  attorneyListSpecialPricing: [],
  mostActiveAttorneys: [],
  mostActiveDateRange: [],
  mostActiveDateRangeAndComparison: [],
  newAttorneys: [],
  attorneySearch: [],
  freeTrialsOffered: [],
  freeTrialsUsed: [],
  lettersSent: [],
  requests: [],
  stateInfo: {},
  statusInfo: {},
  notUsingUs: {},
  activeBills: {},
  attorneyRequest: {},
  attorneyLetters: {},
  attorneyPriors: {},
  attorneyNewBills: {},
  specialPricing: {},
  activeDateRangeAttorneys: {},
  attorneyNewDateRange: {},
  freeTrialsByDate: {},
  attorneyLettersSent: {},
  attorneyRequestsSent: {},
  volumeReport: {},
  attorneyFreeTrialDateRange: {},
  recentFiveAttorneys: [],
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case FOLIO_ATTORNEY_DETAILS:
      return {
        ...state,
        attorneyProfile: action.payload,
        isFetching: false,
      };
    case FOLIO_ATTORNEY_DETAILS_TO_EDIT:
      return {
        ...state,
        attorneyProfileToEdit: action.payload,
        isFetching: false,
      };
    case ATTORNEY_SPECIAL_PRICING:
      return {
        ...state,
        specialPricingDetails: action.payload,
        isFetching: false,
      };
    case GET_STATES:
      return {
        ...state,
        states: action.payload,
        isFetching: false,
      };
    case REFERRAL_SOURCE:
      return {
        ...state,
        referralSources: action.payload,
        isFetching: false,
      };
    case GET_COUNTY_BY_STATE_CODE:
      return {
        ...state,
        counties: action.payload,
        isFetching: false,
      };
    case ATTORNEY_ACCOUNT_AGENT_LIST:
      return {
        ...state,
        agentList: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEYS:
      return {
        ...state,
        attorneyList: action.payload,
        isFetching: false,
      };
    case MY_RECENT_ATTORNEY:
      return {
        ...state,
        recentAttorneys: action.payload,
        isFetching: false,
      };
    case FIVE_RECENT_ATTORNEY:
      return {
        ...state,
        recentFiveAttorneys: action.payload,
        // isFetching: false
      };
    case RECENT_OR_PENDING_REQUEST:
      return {
        ...state,
        recentOrPendingRequests: action.payload,
        isFetching: false,
      };
    case JUDICIAL_DISTRICTS:
      return {
        ...state,
        judicialDistricts: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_BY_STATE:
      return {
        ...state,
        attorneyListByState: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_BY_STATUS:
      return {
        ...state,
        attorneyListByStatus: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_BY_NOT_USING_US_CODE:
      return {
        ...state,
        attorneyListByNotUsingUsCode: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_ACTIVE_BILLING:
      return {
        ...state,
        attorneyListByActiveBilling: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_SPECIAL_PRICING:
      return {
        ...state,
        attorneyListSpecialPricing: action.payload,
        isFetching: false,
      };
    case LIST_ATTORNEY_WITH_NEW_BILLING:
      return {
        ...state,
        attorneyListWithNewBilling: action.payload,
        isFetching: false,
      };
    case MOST_ACTIVE_ATTORNEYS:
      return {
        ...state,
        mostActiveAttorneys: action.payload,
        isFetching: false,
      };
    case MOST_ACTIVE_DATE_RANGE:
      return {
        ...state,
        mostActiveDateRange: action.payload,
        isFetching: false,
      };
    case MOST_ACTIVE_DATE_RANGE_AND_COMPARISON:
      return {
        ...state,
        mostActiveDateRangeAndComparison: action.payload,
        isFetching: false,
      };
    case NEW_ATTORNEYS:
      return {
        ...state,
        newAttorneys: action.payload,
        isFetching: false,
      };
    case FREE_TRIALS_OFFERED:
      return {
        ...state,
        freeTrialsOffered: action.payload,
        isFetching: false,
      };
    case FREE_TRIALS_USED:
      return {
        ...state,
        freeTrialsUsed: action.payload,
        isFetching: false,
      };
    case LETTERS_SENT:
      return {
        ...state,
        lettersSent: action.payload,
        isFetching: false,
      };
    case REQUESTS:
      return {
        ...state,
        requests: action.payload,
        isFetching: false,
      };
    case LIST_BY_STATE:
      return {
        ...state,
        stateInfo: action.payload,
        isFetching: false,
      };
    case LIST_BY_STATUS:
      return {
        ...state,
        statusInfo: action.payload,
        isFetching: false,
      };
    case NOT_USING_US_CODE:
      return {
        ...state,
        notUsingUs: action.payload,
        isFetching: false,
      };
    case ACTIVE_BILLS:
      return {
        ...state,
        activeBills: action.payload,
        isFetching: false,
      };
    case SPECIAL_PRICING:
      return {
        ...state,
        specialPricing: action.payload,
        isFetching: false,
      };
    case MOST_ACTIVE_DATE_RANGE_ATTORNEYS:
      return {
        ...state,
        activeDateRangeAttorneys: action.payload,
        isFetching: false,
      };
    case ATTORNEY_ADDED_NEW_BILLING:
      return {
        ...state,
        attorneyNewBills: action.payload,
      };
    case FREE_TRIALS_PARAMS:
      return {
        ...state,
        freeTrialsByDate: action.payload,
      };

    case VOLUME_REPORT_PARAMS:
      return {
        ...state,
        volumeReport: action.payload,
      };
    case ATTORNEY_ADDED_NEW_DATE_RANGE_PARAMS:
      return {
        ...state,
        attorneyNewDateRange: action.payload,
      };
    case LETTER_SENT_PARAMS:
      return {
        ...state,
        attorneyLettersSent: action.payload,
      };

    case REQUEST_INBOUND_PARAMS:
      return {
        ...state,
        attorneyRequestsSent: action.payload,
      };
    case ATTORNEY_SEARCH:
      return {
        ...state,
        attorneySearch: action.payload,
        isFetching: false,
      };
    case ATTORNEY_PROFILE_REQUEST:
      return {
        ...state,
        attorneyRequest: action.payload,
      };

    case DELETE_ATTORNEY_PROFILE_REQUEST:
      return {
        ...state,
        attorneyRequest: action.payload,
      };

    case EDIT_ATTORNEY_REQUEST_LIST:
      let date = new Date();
      date = date.toISOString();
      let attorneyRequestCopy = { ...state.attorneyRequest };
      let object = { ...state.attorneyRequest.data[action.payload.index] };
      object.RequestAgentID = action.payload.reqBody.requestAgentId;
      object.RequestAssignedToAgentID =
        action.payload.reqBody.requestAssignedToAgentId;
      object.RequestCategory = action.payload.reqBody.requestCategory;
      object.RequestPriority = action.payload.reqBody.requestPriority;
      object.RequestAttorneyID = action.payload.reqBody.requestAttorneyId;
      object.RequestStatus = action.payload.reqBody.requestStatus;
      object.RequestTopic = action.payload.reqBody.requestTopic;
      object.RequestDateCompleted = date;
      attorneyRequestCopy.data[action.payload.index] = object;
      return {
        ...state,
        attorneyRequest: attorneyRequestCopy,
      };
    case EDIT_RECENT_REQUEST:
      let recentOrPendingRequestsCopy = { ...state.recentOrPendingRequests };
      if (action.payload.reqBody.requestStatus === '0') {
        date = new Date();
        date = date.toISOString();
        object = {
          ...state.recentOrPendingRequests.data[action.payload.index],
        };
        object.RequestAgentID = action.payload.reqBody.requestAgentId;
        object.RequestAssignedToAgentID =
          action.payload.reqBody.requestAssignedToAgentId;
        object.RequestCategory = action.payload.reqBody.requestCategory;
        object.RequestPriority = action.payload.reqBody.requestPriority;
        object.RequestAttorneyID = action.payload.reqBody.requestAttorneyId;
        object.RequestStatus = action.payload.reqBody.requestStatus;
        object.RequestTopic = action.payload.reqBody.requestTopic;
        object.RequestDateCompleted = date;
        object.requestBy = action.payload.labelNames.requestLabel;
        object.assignedTo = action.payload.labelNames.agentLabel;
        object.requestCatagoryName = action.payload.labelNames.assignedToLabel;
        recentOrPendingRequestsCopy.data[action.payload.index] = object;
        object.RequestCompletedByAgentID =
          action.payload.reqBody.requestCompletedByAgentId === 0
            ? 0
            : action.payload.reqBody.requestCompletedByAgentId;
      } else {
        recentOrPendingRequestsCopy.count -= 1;
        recentOrPendingRequestsCopy.data.splice(action.payload.index, 1);
      }
      return {
        ...state,
        recentOrPendingRequests: recentOrPendingRequestsCopy,
      };
    case ATTORNEY_LETTERS:
      return {
        ...state,
        attorneyLetters: action.payload,
      };
    case ATTORNEY_LETTERS_PRIORS:
      return {
        ...state,
        attorneyPriors: action.payload,
      };
    case FREE_TRIAL_DATE_RANGE_ATTORNEYS:
      return {
        ...state,
        attorneyFreeTrialDateRange: action.payload,
      };
    default:
      return state;
  }
};
