import { ATTORNEY_PROFILE,CLIENT_SEARCH,CLIENT_LISTS,CLIENT_RECORD_CENTER } from '../../constants/types'

const initialValue = {
    isFetching: true,
    clientSearch : [],
    clientRecordCenter : {},
    clientList : {},
    attorneyProfile: {},
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case ATTORNEY_PROFILE:
            return {
                ...state,
                attorneyProfile: action.payload,
                isFetching: false
            }
        case CLIENT_SEARCH:
            return {
                ...state,
                clientSearch: action.payload,
                isFetching: false
            }
        case CLIENT_LISTS : 
            return {
                ...state,
                clientList: action.payload,
                isFetching: false
            }
        case CLIENT_RECORD_CENTER : 
            return {
                ...state,
                clientRecordCenter: action.payload,
                isFetching: false
            }
        default:
            return state
    }
}
