import { LOGIN, FORGOT_PASSWORD,ATTORNEY_LOGIN,GLOBAL_PROFILE,GLOBAL_SEARCH } from '../constants/types'

const initialValue = {
    isLogin: false,
    userDetails: {},
    attorneyDetails : {},
    forgotPasswordResponse: {},
    updatePasswordResponse : {},
    permissionMatrix : {}
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLogin: true,
                userDetails: action.payload
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordResponse: action.payload
            }
        case FORGOT_PASSWORD :
            return {
                ...state,
                updatePasswordResponse : action.payload
            }
        case ATTORNEY_LOGIN : {
            return {
                ...state,
                attorneyDetails : action.payload
            }
        }
        case GLOBAL_PROFILE : {
            return {
                ...state,
                permissionMatrix : action.payload
            }
        }
        case GLOBAL_SEARCH : {
            return {
                ...state,
                searchResponse : action.payload
            }
        }
        default:
            return state
    }
}
