import React from 'react';
import {connect} from 'react-redux'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react';
import { useEffect } from 'react';

function SnackBar(){
  return (
        <>
            <ToastContainer/>
        </>
  );
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(SnackBar)