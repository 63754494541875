// Theme Constants
export const SET_ENABLE_BACKGROUND_IMAGE =
  'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';
export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL =
  'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';
export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW =
  'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW =
  'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR =
  'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR =
  'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';
export const SET_ENABLE_PAGETITLE_ICON =
  'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING =
  'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT =
  'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';
export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY =
  'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';
export const SET_HEADER_BACKGROUND_COLOR =
  'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';
export const USER_TYPE = 'USER_TYPE';

// Modal Constants
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Onboarding constant
export const LOGIN = 'LOGIN';
export const ATTORNEY_LOGIN = 'ATTORNEY_LOGIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const GLOBAL_PROFILE = 'GLOBAL_PROFILE';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';

// Consumer constant
export const RECORD_CENTER = 'RECORD_CENTER';
export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const PRE_FILING_INFO = 'PRE_FILING_INFO';
export const POST_FILING_INFO = 'POST_FILING_INFO';
export const RECEIVED_TIME = 'RECEIVED_TIME';
export const UPDATE_COURSE_TIME = 'UPDATE_COURSE_TIME';
export const SET_PRE_FILING_COURSE_STEP = 'SET_PRE_FILING_COURSE_STEP';
export const SET_POST_FILING_COURSE_STEP = 'SET_POST_FILING_COURSE_STEP';
export const UPDATE_POST_FILING_STEP = 'UPDATE_POST_FILING_STEP';
export const UPDATE_PRE_FILING_STEP = 'UPDATE_PRE_FILING_STEP';

// Admin Constant
export const NOTES = 'NOTES';
export const LOGS = 'LOGS';
export const ACCOUNT = 'ACCOUNT';
export const CREDIT_COUNSELLING = 'CREDIT_COUNSELLING';
export const DEBTOR_EDUCATION = 'DEBTOR_EDUCATION';
export const AWAITING_CERTIFICATION = 'AWAITING_CERTIFICATION';
export const MANDATORY_INTERACTION = 'MANDATORY_INTERACTION';
export const ELIGIBLE_CERTIFICATION = 'ELIGIBLE_CERTIFICATION';
export const COUNSELORS = 'COUNSELORS';
export const DELETE_COUNSELOR = 'DELETE_COUNSELOR';
export const ADD_NOTE = 'ADD_NOTE';
export const GET_NOTES_BY_CONSUMERID = 'GET_NOTES_BY_CONSUMERID';
export const DELETE_NOTE = 'DELETE_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const GET_NOTE_BY_NOTEID = 'GET_NOTE_BY_NOTEID';
export const PENDING_ACTION_PLAN = 'PENDING_ACTION_PLAN';
export const GET_JUDICIAL_STATE = 'GET_JUDICIAL_STATE';
export const GET_JUDICAIL_DISTRICT = 'GET_JUDICAIL_DISTRICT';
export const ADD_EDIT_DEBTOR = 'ADD_EDIT_DEBTOR';
export const ADD_EDIT_ATTORNEY = 'ADD_EDIT_ATTORNEY';
export const ATTORNEY_USERNAME_CHECK = 'ATTORNEY_USERNAME_CHECK';
export const CHECK_USER_NAME = 'CHECK_USER_NAME';
export const MAILROOM_LETTER = 'MAILROOM_LETTER';
export const GET_ACTION_PLAN_QUESTION = 'GET_ACTION_PLAN_QUESTION';
export const UPDATE_ACTION_PLAN_QUESTION = 'UPDATE_ACTION_PLAN_QUESTION';
export const GET_ACTION_PLAN_ANSWER = 'GET_ACTION_PLAN_ANSWER';
export const UPDATE_ACTION_PLAN_ANSWER = 'UPDATE_ACTION_PLAN_ANSWER';
export const GET_POVERTY_GUIDELINES = 'GET_POVERTY_GUIDELINES';
export const ADD_COUNSELOR = 'ADD_COUNSELOR';
export const VIEW_COUNSELOR_BY_ID = 'VIEW_COUNSELOR_BY_ID';
export const UPDATE_POVERTY_GUIDELINES = 'UPDATE_POVERTY_GUIDELINES';
export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const DELETE_ACTION_PLAN = 'DELETE_ACTION_PLAN';
export const ACTION_PLAN_REVIEW = 'ACTION_PLAN_REVIEW';

// Attorney Relations constant
export const FOLIO_ATTORNEY_DETAILS = 'FOLIO_ATTORNEY_DETAILS';
export const FOLIO_ATTORNEY_DETAILS_TO_EDIT = 'FOLIO_ATTORNEY_DETAILS_TO_EDIT';
export const ATTORNEY_PROFILE_REQUEST = 'REQUEST';
export const DELETE_ATTORNEY_PROFILE_REQUEST = 'DELETE_ATTORNEY_REQUEST';
export const EDIT_ATTORNEY_REQUEST_LIST = 'EDIT_ATTORNEY_REQUEST_LIST';
export const EDIT_RECENT_REQUEST = 'EDIT_RECENT_REQUEST';
export const ATTORNEY_LETTERS = 'ATTORNEY_LETTERS';
export const ATTORNEY_LETTERS_PRIORS = 'ATTORNEY_LETTERS_PRIORS';

export const ATTORNEY_SPECIAL_PRICING = 'ATTORNEY_SPECIAL_PRICING';
export const LIST_ATTORNEYS = 'LIST_ATTORNEYS';
export const RECENT_OR_PENDING_REQUEST = 'RECENT_OR_PENDING_REQUEST';
export const MY_RECENT_ATTORNEY = 'MY_RECENT_ATTORNEY';
export const FIVE_RECENT_ATTORNEY = 'FIVE_RECENT_ATTORNEY';
export const LIST_ATTORNEY_BY_STATE = 'LIST_ATTORNEY_BY_STATE';
export const LIST_ATTORNEY_BY_STATUS = 'LIST_ATTORNEY_BY_STATUS';
export const LIST_ATTORNEY_BY_NOT_USING_US_CODE =
  '​LIST_ATTORNEY_BY_NOT_USING_US_CODE';
export const LIST_ATTORNEY_WITH_NEW_BILLING = 'LIST_ATTORNEY_WITH_NEW_BILLING';
export const LIST_ATTORNEY_ACTIVE_BILLING = 'LIST_ATTORNEY_ACTIVE_BILLING';
export const LIST_ATTORNEY_SPECIAL_PRICING = 'LIST_ATTORNEY_SPECIAL_PRICING';
export const MOST_ACTIVE_ATTORNEYS = 'MOST_ACTIVE_ATTORNEYS';
export const MOST_ACTIVE_DATE_RANGE = 'MOST_ACTIVE_DATE_RANGE';
export const MOST_ACTIVE_DATE_RANGE_AND_COMPARISON =
  'MOST_ACTIVE_DATE_RANGE_AND_COMPARISON';
export const NEW_ATTORNEYS = 'NEW_ATTORNEYS';
export const FREE_TRIALS_OFFERED = 'FREE_TRIALS_OFFERED';
export const FREE_TRIALS_USED = 'FREE_TRIALS_USED';
export const LETTERS_SENT = 'LETTERS_SENT';
export const REQUESTS = 'REQUESTS';
export const LIST_BY_STATE = 'LIST_BY_STATE';
export const LIST_BY_STATUS = 'LIST_BY_STATUS';
export const NOT_USING_US_CODE = 'NOT_USING_US_CODE';
export const ACTIVE_BILLS = 'ACTIVE_BILLS';
export const ATTORNEY_SEARCH = 'ATTORNEY_SEARCH';
export const ATTORNEY_ADDED_NEW_BILLING = 'ATTORNEY_ADDED_NEW_BILLING';
export const FREE_TRIALS_PARAMS = 'FREE_TRIALS_PARAMS';
export const LETTER_SENT_PARAMS = 'LETTER_SENT_PARAMS';
export const REQUEST_INBOUND_PARAMS = 'REQUEST_INBOUND_PARAMS';
export const VOLUME_REPORT_PARAMS = 'VOLUME_REPORT_PARAMS';

// Constants | Type for APIs that return constants
export const ATTORNEY_ACCOUNT_AGENT_LIST = 'ATTORNEY_ACCOUNT_AGENT_LIST';
export const GET_STATES = 'GET_STATES';
export const GET_COUNTY_BY_STATE_CODE = 'GET_COUNTRY_BY_STATUS_CODE';
export const REFERRAL_SOURCE = 'REFERRAL_SOURCE';
export const JUDICIAL_DISTRICTS = 'JUDICIAL_DISTRICTS';
export const JUDICIAL_DISTRICTS_DIVISIONS = 'JUDICIAL_DISTRICTS_DIVISIONS';
export const CONSUMER_JUDICIAL_DISTRICTS = 'CONSUMER_JUDICIAL_DISTRICTS';
export const REQUEST_CATEGORY = 'REQUEST_CATEGORY';
export const ATTORNEY_ACTIVE_ACCOUNT_AGENT_LIST =
  'ATTORNEY_ACTIVE_ACCOUNT_AGENT_LIST';

// Debtor Constants
export const FOLIO_VIEW = 'FOLIO_VIEW';
export const EDIT_IS_SPLITING = 'EDIT_IS_SPLITING';
export const PAYMENT_HISTORY = 'PAYMENT_HISTORY';
export const LOGIN_HISTORY = 'LOGIN_HISTORY';
export const AGENT_LOG = 'AGENT_LOG';
export const SYSTEM_ADMINSTRATION_NOTES = 'SYSTEM_ADMINSTRATION_NOTES';
export const EDIT_DELIVERY_COURSE_ID = 'EDIT_DELIVERY_COURSE_ID';

// Helper Constant
export const INITITAE_LOADER = 'INITITAE_LOADER';
export const GET_NOTES = 'GET_NOTES';

// ATTORNEY MODULE CONSTANT
export const ATTORNEY_PROFILE = 'ATTORNEY_PROFILE';
export const CLIENT_SEARCH = 'CLIENT_SEARCH';
export const CLIENT_LISTS = 'CLIENT_LISTS';
export const CLIENT_RECORD_CENTER = 'CLIENT_RECORD_CENTER';
export const SPECIAL_PRICING = 'SPECIAL_PRICING';
export const MOST_ACTIVE_DATE_RANGE_ATTORNEYS =
  'MOST_ACTIVE_DATE_RANGE_ATTORNEYS';
export const ATTORNEY_ADDED_NEW_DATE_RANGE_PARAMS =
  'ATTORNEY_ADDED_NEW_DATE_RANGE_PARAMS';
export const FREE_TRIAL_DATE_RANGE_ATTORNEYS =
  'FREE_TRIAL_DATE_RANGE_ATTORNEYS';
