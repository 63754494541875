import { INITITAE_LOADER } from '../constants/types'


const initialValue = {
    isLoading: false
}

export default (state = initialValue, action) => {
    if (action.type == INITITAE_LOADER) {
        return {
            isLoading: action.payload
        }
    }
    return state
}
