import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from '../src/locales/en/eng.json';
import translationEsp from '../src/locales/esp/esp.json';
import postFilingEng from './locales/en/postFiling.json';
import postFilingEsp from './locales/esp/postFiling.json';
import navigationEng from './locales/en/navigation.json';
import navigationEsp from './locales/esp/navigation.json';
import registrationEng from './locales/en/registration.json';
import registrationEsp from './locales/esp/registration.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'eng',
    fallbackLng: 'eng',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      eng: {
        translations: translationEng,
        postFiling: postFilingEng,
        navigation: navigationEng,
        registration: registrationEng,
      },
      esp: {
        translations: translationEsp,
        postFiling: postFilingEsp,
        navigation: navigationEsp,
        registration: registrationEsp,
      },
    },
    ns: ['translations', 'postFiling', 'navigation', 'registration'],
    defaultNS: 'translations',
  });

export default i18n;
