import {
    ATTORNEY_ACTIVE_ACCOUNT_AGENT_LIST,
    REQUEST_CATEGORY
} from '../constants/types'

const initialValue = {
    requestCategory : [],
    attorneyActiveAccounts : []
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case ATTORNEY_ACTIVE_ACCOUNT_AGENT_LIST:
            return {
                ...state,
                attorneyActiveAccounts : action.payload
            }
        case REQUEST_CATEGORY : 
            return {
                ...state,
                requestCategory : action.payload
            }
        default:
            return state
    }
}