import React, { PureComponent } from 'react'

class ErrorBoundary extends PureComponent {
    state = {
        isError: false,
        errorMessage: ''
    }

    static getDerivedStateFromError(error) {
        return { isError: true, errorMessage: error.toString() }
    }

    componentDidCatch(error, info) {
        this.logErrorToServices(error.toString(), info.componentStack)
    }
    

    render() {

        if (this.state.isError) {
            return (<p>Error Occured.</p>);
        }
        return this.props.children;
    }
}

export default ErrorBoundary;