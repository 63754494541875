import { Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
// Loader
import Loading from '../../helperComponents/loading';

const Admin = lazy(() => import('../../components/admin/index'));
const Attorney = lazy(() => import('../../components/Attorney/index'));
const Consumer = lazy(() => import('../../components/consumer/index'));

const AppMain = () => {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="">
            <Loading />
          </div>
        }
      >
        <Switch>
          <Route path="/admin">
            <Switch>
              <Admin />
            </Switch>
          </Route>
          <Route path="/attorney">
            <Switch>
              <Attorney />
            </Switch>
          </Route>
          <Route path="/">
            <Switch>
              <Consumer />
            </Switch>
          </Route>
          <Route component={() => <div>404 Main</div>} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default withRouter(AppMain);
