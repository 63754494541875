import {
    GET_ACTION_PLAN_QUESTION,UPDATE_ACTION_PLAN_QUESTION,
    GET_ACTION_PLAN_ANSWER,UPDATE_ACTION_PLAN_ANSWER, 
    GET_POVERTY_GUIDELINES, UPDATE_POVERTY_GUIDELINES,
    DELETE_ACTION_PLAN
} from '../../constants/types'

const initialValue = {
    actionPlanQuestion : {},
    actionPlanUpdateResponse : {},
    actionPlanAnswer : {},
    actionPlanAnswerUpdateResponse : {},
    povertyGuidelines : {},
    povertyGuidelinesUpdateResponse : {},
    actionPlanDeleteResponse : {},
    offset : 10,
    page : 1
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case GET_ACTION_PLAN_QUESTION:
            return {
                ...state,
                actionPlanQuestion : action.payload
            }
        case UPDATE_ACTION_PLAN_QUESTION : 
            return {
                ...state,
                actionPlanUpdateResponse : action.payload
            }

        case GET_ACTION_PLAN_ANSWER:
                return {
                    ...state,
                    actionPlanAnswer : action.payload
                }
        case UPDATE_ACTION_PLAN_ANSWER : 
            return {
                ...state,
                actionPlanAnswerUpdateResponse : action.payload
            }
        case GET_POVERTY_GUIDELINES:
                return {
                    ...state,
                    povertyGuidelines : action.payload
                }
        case UPDATE_POVERTY_GUIDELINES : 
            return {
                ...state,
                povertyGuidelinesUpdateResponse : action.payload
            }
        case DELETE_ACTION_PLAN : 
            return {
                ...state,
                actionPlanDeleteResponse : action.payload
            }
        default:
            return state
    }
}