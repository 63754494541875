import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../../Layout/AppMain/index';
import Loader from '../../helperComponents/loading'
import BaseContext from '../../contextAPI/provider'
import SnackBar from '../../helperComponents/snackBar'

class Main extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };    
    }

    render() {

        const { loadingStatus } = this.props

        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        return (
            <>
                { loadingStatus &&  <Loader/>}
                <div className="snackbar">
                    <SnackBar/>
                </div>
                <Fragment>
                    <div className={cx(
                        {'fixed-header': enableFixedHeader},
                        {'fixed-sidebar': enableFixedSidebar},
                        {'fixed-footer': enableFixedFooter},
                        {'closed-sidebar': enableClosedSidebar},
                        {'closed-sidebar-mobile': closedSmallerSidebar},
                        {'sidebar-mobile-open': enableMobileMenu},
                        {'body-tabs-shadow-btn': enablePageTabsAlt},
                    )}>     
                        <AppMain/>
                    </div>
                </Fragment>
                
            </>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    loadingStatus : state.loadingStatus.isLoading,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(Main));