import {
    NOTES, LOGS, ACCOUNT, DEBTOR_EDUCATION, CREDIT_COUNSELLING, AWAITING_CERTIFICATION,
    COUNSELORS, ELIGIBLE_CERTIFICATION, PENDING_ACTION_PLAN, ADD_EDIT_DEBTOR, GET_JUDICIAL_STATE, GET_JUDICAIL_DISTRICT, EDIT_DELIVERY_COURSE_ID, ADD_EDIT_ATTORNEY, ATTORNEY_USERNAME_CHECK
    , CHECK_USER_NAME, FOLIO_VIEW, EDIT_IS_SPLITING, SERVICE_REQUEST, ADD_COUNSELOR, ADD_NOTE, GET_NOTES_BY_CONSUMERID, DELETE_NOTE, MANDATORY_INTERACTION, EDIT_NOTE, GET_NOTE_BY_NOTEID, VIEW_COUNSELOR_BY_ID, DELETE_COUNSELOR, MAILROOM_LETTER
    , ACTION_PLAN_REVIEW, CONSUMER_JUDICIAL_DISTRICTS
} from '../../constants/types'

const initialValue = {
    isFetching: true,
    accounts: [],
    debtorEducation: {},
    deleteCouselor: {},
    eligibleCertification: {},
    mandatoryInteration: {},
    folioDetails: {},
    noteByNoteId: {},
    pendingActionPlan: {},
    addDebtorResponse: {},
    addAttorneyResponse: {},
    awaitingCertification: {},
    mailRoomLetters: {},
    judicialCountries: [],
    judicialDistrcit: [],
    consumerJudicialDistricts: [],
    deletedNote: {},
    userNameStatus: {},
    attorneyUserNameStatus: {},
    councelorRespByAgentId: {},
    creditCounselling: {},
    addCouncelorResponse: {},
    serviceRequestResponse: {},
    actionPlan: {},
    notes: {
        rows: [],
        count: 0
    },
    counselorData: {
        rows: [],
        count: 0
    },
    logs: {}
}

export const adminReducer = (state = initialValue, action) => {
    switch (action.type) {
        case NOTES:
            return {
                ...state,
                notes: {
                    count: action.payload.count,
                    rows: state.notes.rows.concat(action.payload.data)
                },
                isFetching: false,
            }
        case LOGS:
            return {
                ...state,
                logs: action.payload,
                isFetching: false,
            }
        case ACCOUNT:
            return {
                ...state,
                accounts: action.payload,
                isFetching: false,
            }
        case AWAITING_CERTIFICATION:
            return {
                ...state,
                awaitingCertification: action.payload,
                isFetching: false
            }
        case ELIGIBLE_CERTIFICATION: {
            return {
                ...state,
                eligibleCertification: action.payload,
                isFetching: false
            }
        }
        case MANDATORY_INTERACTION:
            return {
                ...state,
                mandatoryInteration: action.payload,
                isFetching: false
            }
        case COUNSELORS:
            return {
                ...state,
                counselorData: {
                    count: action.payload.count,
                    rows: action.payload.rows
                },
                isFetching: false
            }
        case CREDIT_COUNSELLING:
            return {
                ...state,
                creditCounselling: action.payload
            }
        case DEBTOR_EDUCATION:
            return {
                ...state,
                debtorEducation: action.payload
            }
        case FOLIO_VIEW:
            return {
                ...state,
                folioDetails: action.payload,
                isFetching: false
            }
        case EDIT_IS_SPLITING:
            let folioDetails = { ...state.folioDetails }
            folioDetails.viewFolioData[0].IsSplitViewing = action.payload
            return {
                ...state,
                folioDetails: folioDetails
            }

        case ACTION_PLAN_REVIEW:
            return {
                ...state,
                actionPlan: action.payload,
                isFetching: false
            }
        case ADD_NOTE:
            return {
                ...state,
                addNote: action.payload
            }
        case EDIT_NOTE:
            return {
                ...state,
                editNote: action.payload
            }
        case GET_NOTES_BY_CONSUMERID:
            return {
                ...state,
                notesByConsumerId: action.payload
            }
        case DELETE_NOTE:
            return {
                ...state,
                deletedNote: action.payload
            }
        case GET_NOTE_BY_NOTEID:
            return {
                ...state,
                noteByNoteId: action.payload
            }
        case PENDING_ACTION_PLAN:
            return {
                ...state,
                pendingActionPlan: action.payload
            }
        case ADD_EDIT_DEBTOR:
            return {
                ...state,
                addDebtorResponse: action.payload
            }
        case ADD_EDIT_ATTORNEY:
            return {
                ...state,
                addAttorneyResponse: action.payload
            }
        case GET_JUDICIAL_STATE:
            return {
                ...state,
                judicialCountries: action.payload
            }
        case GET_JUDICAIL_DISTRICT:
            return {
                ...state,
                judicialDistrcit: action.payload
            }
        case CONSUMER_JUDICIAL_DISTRICTS:
            return {
                ...state,
                consumerJudicialDistricts: action.payload
            }
        case ATTORNEY_USERNAME_CHECK:
            return {
                ...state,
                attorneyUserNameStatus: action.payload
            }
        case CHECK_USER_NAME:
            return {
                ...state,
                userNameStatus: action.payload
            }
        case MAILROOM_LETTER:
            return {
                ...state,
                mailRoomLetters: action.payload
            }
        case SERVICE_REQUEST:
            return {
                ...state,
                serviceRequestResponse: {
                    "status": "success",
                    "data": "action.payload"
                }
            }
        case EDIT_DELIVERY_COURSE_ID:
            let folioDetailsCopy = { ...state.folioDetails }
            folioDetailsCopy.viewFolioData[0].DeliveryCourseTypeID = action.payload
            return {
                ...state,
                folioDetails: folioDetailsCopy
            }
        default:
            return state
    }
}

export const councelorReducer = (state = initialValue, action) => {
    switch (action.type) {
        case ADD_COUNSELOR:
            return {
                ...state,
                addCouncelorResponse: action.payload,
            }
        case VIEW_COUNSELOR_BY_ID:
            return {
                ...state,
                councelorRespByAgentId: action.payload,
                isFetching: false,

            }
        case DELETE_COUNSELOR:
            return {
                ...state,
                deleteCouselor: action.payload
            }
        default:
            return state
    }
}