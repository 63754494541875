import {
  RECORD_CENTER,
  ACCOUNT_INFO,
  PRE_FILING_INFO,
  POST_FILING_INFO,
  RECEIVED_TIME,
  UPDATE_COURSE_TIME,
  SET_PRE_FILING_COURSE_STEP,
  SET_POST_FILING_COURSE_STEP,
  UPDATE_POST_FILING_STEP,
  UPDATE_PRE_FILING_STEP,
  JUDICIAL_DISTRICTS_DIVISIONS,
} from '../../constants/types';

const initialValue = {
  recordCenter: [],
  accountInfo: {},
  preFilingUserInformation: {},
  postFilingUserInformation: {},
  isFetching: true,
  initiallyReceivedTime: 0,
  courseTime: 0,
  preFilingCourseStep: localStorage.getItem('prefiling_course_step')
    ? JSON.parse(localStorage.getItem('prefiling_course_step'))
    : 0,
  postFilingCourseStep: localStorage.getItem('postfiling_course_step')
    ? JSON.parse(localStorage.getItem('postfiling_course_step'))
    : 1,
  judicialDistrictsAndDivisions: [],
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case RECORD_CENTER:
      return {
        ...state,
        isFetching: false,
        recordCenter: action.payload,
      };
    case ACCOUNT_INFO:
      return {
        ...state,
        isFetching: false,
        accountInfo: action.payload,
      };
    case POST_FILING_INFO:
      return {
        ...state,
        postFilingUserInformation: action.payload,
      };
    case PRE_FILING_INFO:
      return {
        ...state,
        preFilingUserInformation: action.payload,
      };
    case RECEIVED_TIME:
      console.log(action);
      return {
        ...state,
        initiallyReceivedTime: action.payload,
        // courseTime: action.payload
      };
    case UPDATE_COURSE_TIME:
      return {
        ...state,
        courseTime: action.payload,
      };
    case SET_PRE_FILING_COURSE_STEP:
      return {
        ...state,
        preFilingCourseStep: action.payload,
      };
    case SET_POST_FILING_COURSE_STEP:
      return {
        ...state,
        postFilingCourseStep: action.payload,
      };
    case UPDATE_POST_FILING_STEP:
      return {
        ...state,
        postFilingCourseStep: action.payload,
      };
    case UPDATE_PRE_FILING_STEP:
      return {
        ...state,
        preFilingCourseStep: action.payload,
      };
    case JUDICIAL_DISTRICTS_DIVISIONS:
      return {
        ...state,
        judicialDistrictsAndDivisions: action.payload,
      };
    default:
      return state;
  }
};
