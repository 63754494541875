import { PAYMENT_HISTORY, LOGIN_HISTORY, SYSTEM_ADMINSTRATION_NOTES, AGENT_LOG } from '../../constants/types'

const initialValue = {
    isFetching: true,
    loginHistory: [],
    agentLog : {},
    paymentHistory: [],
    systemAdminNotes: []
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case PAYMENT_HISTORY:
            return {
                ...state,
                paymentHistory: action.payload,
                isFetching: false
            }
        case LOGIN_HISTORY:
            return {
                ...state,
                loginHistory: action.payload,
                isFetching: false
            }
        case SYSTEM_ADMINSTRATION_NOTES:
            return {
                ...state,
                systemAdminNotes: action.payload,
                isFetching: false
            }
        case AGENT_LOG:
            return {
                ...state,
                agentLog: action.payload,
                isFetching: false
            }
        default:
            return state
    }
}
