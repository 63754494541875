import { PAGINATION, MODAL_STATUS } from '../actions/helper'

const initialValue = {
    offset : 1,
    limit : 10,
    modalStatus : {}
}

export default (state = initialValue, action) => {
    switch (action.type) {
        case PAGINATION:
            return {
                ...state,
                offset : action.payload,
                limit : 10
            }
        case MODAL_STATUS : {
            return {
                ...state,
                modalStatus : action.payload
            }
        }
        default:
            return state
    }
}
